
import { defineComponent, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "merchants-settlement",
  components: {},
  setup() {
    const { t } = useI18n();
    
    const route = useRoute();
    const router = useRouter();

    onMounted(() => {
      setModuleBCN(t, route, router);
    });
  },
});
